import React, { useEffect, useState } from "react";
import drive from "drive-db";
import images from "../../assets/images";
import Footer from "../Footer";
import ListHeader from "../ListHeader";
import MainSpeakerCard from "../MainSpeakerCard";
import SpeakerCard from "../SpeakerCard";
import styles from "./SpeakersSection.module.scss";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import speakers, {
  MainSpeaker1,
  Mainspeaker2,
} from "../../assets/images/speakers";
import axios from "axios";
import { storage } from "../../firebase";
import speakerList from "./speakerList";

function SpeakersSection() {
  const [data, setData] = useState([]);
  const [hosts, setHosts] = useState([]);
  const SpeakersRef = storage.refFromURL(
    "gs://generic-ynet-people.appspot.com/speakers.json"
  );
  const HostsRef = storage.refFromURL(
    "gs://generic-ynet-people.appspot.com/hosts.json"
  );

  useEffect(() => {
    SpeakersRef.getDownloadURL().then((url) => {
      axios.get(url).then((res) => {
        console.log(res.data);
        setData(res.data);
      });
    });

    HostsRef.getDownloadURL().then((url) => {
      axios.get(url).then((res) => {
        console.log(res.data);
        setHosts(res.data);
      });
    });
  }, []);

  return (
    <section id="speakerSection">
      <div
        className={styles.main}
        style={{
          backgroundImage: `url(${images.longBackground})`,
          backgroundColor: "#EC1C24",
        }}
      >
        <div className={styles.container}>
          <div className={styles.mainSpeakerCardContainer}>
            {hosts.length ? (
              hosts.map((item, index) => (
                <SpeakerCard
                  key={index}
                  image={item.image}
                  name={item.name}
                  title={item.title}
                />
              ))
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <p>...טוען</p>
              </div>
            )}
          </div>

          <ListHeader />
          <div className={styles.speakerCardContainer}>
            {data.length ? (
              data.map((item, index) => (
                <SpeakerCard
                  key={index}
                  image={item.image}
                  name={item.name}
                  title={item.title}
                />
              ))
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <p>...טוען</p>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </section>
  );
  console.log(data);
}

export default SpeakersSection;
