import React from "react";
import styles from "./ListHeader.module.scss";
import { Link } from "react-router-dom";
import images from "../../assets/images";
import usePopup from "../../hooks/usePopup";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import gtag from "../../gtag";

function ListHeader() {
  const [popupActive, setPopupActive] = usePopup();
  return (
    <div className={styles.w100}>
      <div className={styles.listHeader}>
        <hr className={styles.headerLine} />
        <div className={styles.titleBox}>
          <h1 className={styles.headerTitle}>משתתפים</h1>
        </div>
        <hr className={styles.headerLine} />
      </div>
      <div className={styles.flexCenter}>
        <p
          className={styles.description}
          onClick={() => {
            setPopupActive(!popupActive);
            gtag.programClick();
          }}
          style={{ cursor: "pointer", textAlign: "center" }}
        >
          לתוכנית הוועידה <br />
          <span>
            <img className="inline-image" src={images.leftArrow} alt="" />
          </span>
          <span className={styles.desc_line2}>לחץ כאן</span>
        </p>
      </div>
    </div>
  );
}

export default ListHeader;
