import React, { useEffect, useRef } from "react";
import images from "../../assets/images";
import styles from "./Header.module.scss";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { findDOMNode } from "react-dom";

function Header() {
  const refVideo = useRef(null);
  const scrollToSpeakers = () => {
    document
      .getElementById("speakerSection")
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section>
      <div
        className={styles.main}
        style={{
          background: `url(${images.longBackground})`,
          backgroundColor: "#FFB733",
        }}
      >
        <Zoom>
          <img className={styles.headerImage} src={images.header} alt="" />
        </Zoom>
        <Fade up delay={500}>
          <p className={styles.headerTextBlack}>ולאשה ynet ,ידיעות אחרונות</p>
          <p className={styles.headerText}>
            בוועידה מיוחדת לרגל יום האישה הבינלאומי
          </p>
          <h1 className={styles.headerDate}>8.3.21</h1>
          <p className={styles.headerText2}>
            <span>
              <img
                className={styles.inline_video}
                src={images.inline_video}
                alt=""
              />
            </span>
            <span>
              <img
                className={styles.ynetInline}
                src={images.ynetInline}
                alt=""
              />
            </span>
            -שידור חי ב
          </p>
          <p className={styles.headerText2}></p>
        </Fade>

        <img
          onClick={() => scrollToSpeakers()}
          className={styles.downIcon}
          src={images.downIcon}
          alt=""
        />
      </div>
    </section>
  );
}

export default Header;
