import React from "react";
import styles from "./Footer.module.scss";
import images from "../../assets/images";
import usePopup from "../../hooks/usePopup";
import partners from "../../assets/images/partners";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import gtag from "../../gtag";
import sponsors from "../../assets/images/sponsors";

function Footer() {
  const [popupActive, setPopupActive] = usePopup();
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.lineContainer}>
          <hr className={styles.line} />
          <h1
            className={styles.title}
            onClick={() => {
              setPopupActive(!popupActive);
              gtag.programClick();
            }}
          >
            <span>
              <img className="inline-image" src={images.leftArrow} alt="" />
            </span>
            לתוכנית הוועידה לחץ כאן
          </h1>
          <hr className={styles.line} />
        </div>

        <p className={styles.description}>
          צילומים: עידו לביא, תומר קופרשמיט, דקלה שי, מועצה מקומית ירוחם, ראובן
          קופיצ'ינסקי, איתמר רותם, אבי מועלם, איציק בירן, רונן חורש, אפרת סער,
          דורון לאטצר, ניב קנטור, ליאת מנדל, תיעוד רגעים, סיוון שחור, שי קדם,
          רמי זנגר, אוריאל כהן, גיא כושי ויריב פיין, ענבל מרמרי, דוברות עיריית
          יהוד מונוסון, שלמה ערד, ליה יפה, גבריאל בהרלייה, רונן אקרמן, חן גלילי,
          אלכס קולומויסקי, מיכל קופר, דימיטרי גרין, אדריאנה חייפץ, משה ששון
          ועידו נתנאל, אור דנון, ענבל מרמרי, ליאת מנדל, שי פרנקו, אור דנון, חיה
          דובוב, אביגיל עוזי, עמית אחאבן, שלומי אמסלם, יחיאל גורפיין, גיל
          נחושתן , ישראל חופשית, ליאת מנדל, יצחק הררי, מונא אבו שחאדה .
        </p>
        <div className={styles.w100}></div>
      </div>
      <div className={styles.partnersContainer}>
        {partners.map((item) => (
          <img className={styles.partners} src={item} alt="" />
        ))}
      </div>
      <div className={styles.sponsorContainer}>
        {sponsors.map((item) => (
          <img className={styles.sponsors} src={item} alt="" />
        ))}
      </div>
    </div>
  );
}

export default Footer;
