import partner1 from "./partner1.png";
import partner2 from "./partner2.png";
import partner3 from "./partner3.png";
import partner4 from "./partner4.png";
import partner5 from "./partner5.png";
import partner6 from "./partner6.png";
import partner7 from "./partner7.png";
import partner8 from "./partner8.png";

const partners = [partner1, partner2, partner3, partner4, partner5, partner6];

export default partners;
