import firebase from 'firebase'
import 'firebase/storage'

export const app = firebase.initializeApp({
  apiKey: "AIzaSyAblqYALh449KP1ElrhxKrmNVWIhdGRTa0",
  authDomain: "generic-ynet-people.firebaseapp.com",
  projectId: "generic-ynet-people",
  storageBucket: "generic-ynet-people.appspot.com",
  messagingSenderId: "250984308588",
  appId: "1:250984308588:web:6fe1a741f93aafa25f9d49"
});

export const storage = firebase.storage()

